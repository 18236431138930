import React from "react"

export const LogoIcon = ({ color = "#00157D" }: any) => {
	return (
		<svg id="raise-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87 100">
			<defs />
			<g fill="none" fillRule="evenodd">
				<path
					fill={color}
					d="M13 33.86234C13 16.83916 26.9094 3 43.99975 3 61.0906 3 75 16.83916 75 33.86234 75 43.61686 70.43077 52.3462 63.30538 58L43.99975 24.48103l-19.30462 33.5029C17.56873 52.3462 13 43.61686 13 33.86234zM87 100L64.30703 60.59173c8.0149-6.18902 13.1867-15.87559 13.1867-26.75303C77.49374 15.17858 62.24054 0 43.5078 0 24.77555 0 9.52183 15.17858 9.52183 33.8387c0 10.87744 5.17231 20.56401 13.18721 26.75303L0 100h87z"
				/>
			</g>
		</svg>
	)
}
