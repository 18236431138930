import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { BookYourService, HolisticMarketplace, PersonalGuidance } from "../icons"
import { StyledContainer, StyledBlock, StyledH3, StyledText } from "../styled"

const StyledSubBlock: any = styled.div`
	margin-bottom: 64px;

	svg {
		margin-bottom: 30px;
		display: flex;
		height: 48px;
		width: 48px;
	}

	p {
		margin-bottom: 0;
	}
`

const StyledSubBlockTitle: any = styled.div`
	display: flex;
	align-items: flex-end;
	margin-bottom: 19px;
	position: relative;

	@media (min-width: 1024px) {
		margin-bottom: 39px;
	}

	h3 {
		margin-bottom: 0;

		@media (min-width: 1024px) {
			font-size: 34px;
		}
	}
`

const StyledFeatures: any = styled.div`
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 100px;
	}
`
const Features = ({}: any) => {
	return (
		<StyledBlock grey>
			<StyledContainer>
				<StyledFeatures>
					<StyledSubBlock>
						<HolisticMarketplace />
						<StyledSubBlockTitle>
							<StyledH3>Holistic Marketplace</StyledH3>
						</StyledSubBlockTitle>
						<StyledText landing>
							Explore a variety of different healing methods, personal development tools and spiritual practices.
						</StyledText>
					</StyledSubBlock>
					<StyledSubBlock>
						<PersonalGuidance />
						<StyledSubBlockTitle>
							<StyledH3>Guided Assistant</StyledH3>
						</StyledSubBlockTitle>
						<StyledText landing>
							Ask for personal guidance on holistic services and find matching practitioners for your needs.
						</StyledText>
					</StyledSubBlock>
					<StyledSubBlock>
						<BookYourService />
						<StyledSubBlockTitle>
							<StyledH3>In-app booking</StyledH3>
						</StyledSubBlockTitle>
						<StyledText landing>
							Easy-to-use booking system for online and offline sessions, courses and events with our service providers.
						</StyledText>
					</StyledSubBlock>
				</StyledFeatures>
			</StyledContainer>
		</StyledBlock>
	)
}

export default Features
