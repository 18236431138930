import React from "react"
import styled from "styled-components"
import { StyledContainer, StyledBlock, StyledH2, StyledText } from "../styled"

const StyledDemo: any = styled.div`
	${StyledBlock} {
		@media (min-width: 767px) {
			padding-top: 160px;
			padding-bottom: 120px;
		}

		position: relative;
		overflow: hidden;

		.gradient {
			z-index: -1;
			position: absolute;
			top: 0;
			left: -1000px;
			right: -1000px;
			bottom: 0;
			background: #eeeef1;
		}

		iframe {
			max-width: 100%;

			@media (max-width: 767px) {
				height: 240px;
			}
		}

		${StyledH2} {
			margin-bottom: 48px;
		}

		${StyledText} {
			max-width: 640px;
			margin-top: 36px;
		}
	}
`

const Demo = () => {
	return (
		<StyledDemo>
			<StyledBlock>
				<StyledContainer>
					<StyledH2>Ask the Oracle</StyledH2>
					<iframe
						src="https://player.vimeo.com/video/513794365"
						width="640"
						height="360"
						frameBorder="0"
						allow="autoplay; fullscreen; picture-in-picture"
						{...{
							webkitallowfullscreen: "true",
							mozallowfullscreen: "true",
							allowFullScreen: true,
						}}
					></iframe>
					<StyledText landing>
						Feeling lost about which methods might be best for your needs? <br className="hidden--desktop" />
						Our guided assistant will examine your situation and help you find relevant experts for your goals and
						well-being.
					</StyledText>
				</StyledContainer>
				<div className="gradient" />
			</StyledBlock>
		</StyledDemo>
	)
}

export default Demo
