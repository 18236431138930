import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { useOnScreen } from "../../util/hooks"
import { LogoIcon } from "../icons/LogoIcon"
import StageImage from "../images/StageImage"
import { StyledButton, StyledContainer, StyledBlock, StyledText } from "../styled"
import StageVideo from "../video/StageVideo"

const StyledStage: any = styled.div`
	position: relative;
	overflow: hidden;

	#raise-icon {
		height: 44px;
		width: 44px;
		margin: 0 auto;
		display: block;
		margin-bottom: 48px;

		@media (min-width: 1024px) {
			height: 77px;
			width: 77px;
			margin-bottom: 48px;
		}
	}
`
const StyledHeadline: any = styled.h1`
	display: flex;
	flex-direction: column;
	font-size: 30px;
	text-transform: uppercase;
	font-weight: 400;
	color: #fff;
	margin-bottom: 64px;
	margin-right: auto;
	margin-left: auto;
	max-width: 360px;
	padding: 0 12px;

	span:nth-child(1) {
		margin-left: 24px;
	}

	span:nth-child(2) {
		align-self: flex-end;
	}

	@media (min-width: 1024px) {
		font-size: 85px;
		font-weight: 300;
		max-width: 1020px;
		line-height: 100%;
	}
`

const StyledContent: any = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		margin-bottom: 10vh;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 400;

		@media (min-width: 1024px) {
			font-size: 18px;
			max-width: 450px;
			margin-bottom: 8vh;
		}
	}
`

const Stage = ({ changeHeaderColor, defaultHeaderColor, toggleRegisterForm }: any) => {
	const ref = useRef()
	const isVisible = useOnScreen(ref)

	useEffect(() => {
		changeHeaderColor(isVisible ? "#fff" : defaultHeaderColor)
	}, [isVisible])

	return (
		<StyledStage>
			<StyledBlock stage ref={ref}>
				<LogoIcon color={"#fff"} />
				<StyledHeadline>
					<span>Become part of</span>
					<span>a conscious</span>
					<span>collective.</span>
				</StyledHeadline>
				<StyledContainer>
					<StyledContent>
						<StyledButton onClick={toggleRegisterForm} shadow noBorder centered>
							Join now
						</StyledButton>
					</StyledContent>
				</StyledContainer>
			</StyledBlock>
			<StageVideo />
		</StyledStage>
	)
}

const mapStateToProps = ({ defaultHeaderColor }: any) => ({ defaultHeaderColor })

const mapDispatchToProps = (dispatch: any) => ({
	changeHeaderColor: (payload: boolean) => dispatch({ type: `CHANGE_HEADER_COLOR`, payload }),
	toggleRegisterForm: () => dispatch({ type: `TOGGLE_REGISTER_FORM` }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Stage)
