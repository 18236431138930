import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import LandingImage from "../images/LandingImage"
import { StyledContainer, StyledBlock, StyledH2, StyledText, StyledButton } from "../styled"

const StyledImages: any = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 12px;
	margin-bottom: 64px;
`

const StyledJoinSection1: any = styled.div`
	position: relative;

	@media (max-width: 1023px) {
		.placeholder {
			display: none;
		}
	}

	@media (min-width: 1024px) {
		${StyledContainer} {
			position: relative;
		}
		${StyledH2} {
			max-width: 480px;
		}
		${StyledText} {
			max-width: 300px;
		}
	}
`

const StyledImagesMobile: any = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}
`

const StyledImagesDesktop: any = styled.div`
	@media (max-width: 1023px) {
		display: none;
	}

	${StyledImages} {
		position: absolute;
		right: 0;
		top: 24px;
		max-width: 60%;
		width: 100%;
		height: 100%;

		img {
			max-width: 224px;
			max-height: 295px;
		}
	}
`

const StyledJoinSection2: any = styled.div`
	@media (min-width: 1024px) {
		${StyledH2} {
			max-width: 430px;
		}

		${StyledText} {
			font-size: 14px;
			max-width: 140px;
		}

		.section-2-text-wrapper {
			margin-top: 230px;
			display: flex;
		}

		.section-2-headline-wrapper {
			display: inline-flex;
			flex-direction: column;
		}
	}
`

const StyledJoin: any = styled.div`
	@media (max-width: 1023px) {
		${StyledBlock} {
			padding-top: 50px;
		}
	}
`

const Join = ({ toggleRegisterForm }: any) => {
	const Images = () => (
		<StyledImages>
			<div className="placeholder" />
			<LandingImage fileName="eylam.png" />
			<LandingImage fileName="lia.png" />
			<LandingImage fileName="liora.png" />
			<LandingImage fileName="toyah.png" />
			<LandingImage fileName="larissa.png" />
			<div className="placeholder" />
			<LandingImage fileName="alisa.png" />
			<LandingImage className="placeholder" fileName="nic.png" />
			<div className="placeholder" />
		</StyledImages>
	)

	const Headline = () => <StyledH2>Join our Collective</StyledH2>
	const Text = () => (
		<StyledText landing>
			If you are coach, therapist or healer who is interested to offer your services with us, register via our
			application tool.
		</StyledText>
	)
	const Button = () => (
		<StyledButton onClick={toggleRegisterForm} dark>
			Register Now
		</StyledButton>
	)
	return (
		<StyledJoin>
			<StyledBlock>
				<StyledJoinSection1>
					<StyledImagesDesktop>
						<StyledContainer>
							<Images />
						</StyledContainer>
					</StyledImagesDesktop>
					<StyledContainer>
						<StyledH2>From touch screen to touch base</StyledH2>
						<StyledText landing>
							Meet a diverse collective of humans who would be happy to consult, coach or work with you on your topics
							and book your service instantly.
						</StyledText>
					</StyledContainer>
					<StyledImagesMobile>
						<Images />
					</StyledImagesMobile>
				</StyledJoinSection1>
				<StyledJoinSection2>
					<div className="hidden--desktop">
						<StyledContainer>
							<Headline />
							<Text />
							<Button />
						</StyledContainer>
					</div>
					<div className="hidden--mobile">
						<StyledContainer>
							<div className="section-2-text-wrapper">
								<div className="section-2-headline-wrapper">
									<Headline />
									<div>
										<Button />
									</div>
								</div>
								<Text />
							</div>
						</StyledContainer>
					</div>
				</StyledJoinSection2>
			</StyledBlock>
		</StyledJoin>
	)
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: any) => ({
	toggleRegisterForm: () => dispatch({ type: `TOGGLE_REGISTER_FORM` }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Join)
