import React from "react"
import styled from "styled-components"
import { StyledContainer, StyledBlock, StyledH2, StyledText } from "../styled"
import OpenerImage from "./../images/OpenerImage"

const StyledOpener: any = styled.div`
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: row-reverse;

		div {
			padding: 0 10px;
		}

		.first {
			width: 40%;
		}

		.second {
			width: 60%;
			margin-right: 60px;
		}

		${StyledH2} {
			font-size: 50px;
		}
	}
`

const Opener = () => {
	return (
		<StyledBlock>
			<StyledContainer>
				<StyledOpener>
					<div className="first">
						<StyledH2>
							Guidance <br className="hidden--mobile" />
							for body, mind &amp; soul
						</StyledH2>
						<StyledText landing>
							Our mission is to create a one-of-a-kind product that will offer services for self-discovery, personal
							development and well-being, so you can reach your full potential.
						</StyledText>
					</div>
					<div className="second">
						<OpenerImage fileName={"app-preview.png"} />
					</div>
				</StyledOpener>
			</StyledContainer>
		</StyledBlock>
	)
}

export default Opener
