import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { useOnScreen } from "../../util/hooks"
import { IllustrationShare } from "../icons"
import { StyledContainer, StyledBlock, StyledH2, StyledText, StyledButton } from "../styled"

const StyledIllustration: any = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin: 48px 0;

	svg {
		height: 130px;
		width: 130px;
	}
`

const StyledShare: any = styled.div`
	@media (min-width: 1024px) {
		display: flex;
		flex-direction: row-reverse;

		.column {
			width: 100%;
			margin-left: 100px;
		}

		${StyledH2} {
			max-width: 500px;
		}

		${StyledText} {
			width: 100%;
			max-width: 600px;
		}

		${StyledIllustration} {
			svg {
				height: 300px;
				width: 300px;
			}
		}
	}
`

const Share = ({}: any) => {
	return (
		<StyledBlock>
			<StyledContainer>
				<StyledShare>
					<div className="column">
						<StyledH2>Share our vision of a new world?</StyledH2>
						<StyledText landing>
							Join us on our journey to create a platform for awareness and healthcare culture. We are now seeking out
							accelerators and investors who want to help manifest our vision and partner with us.
						</StyledText>
					</div>
					<StyledIllustration>
						<IllustrationShare />
					</StyledIllustration>
					{/* <StyledButton centered>
						<Link className="button" to="/share-vision">
							Discover
						</Link>
					</StyledButton> */}
				</StyledShare>
			</StyledContainer>
		</StyledBlock>
	)
}

export default Share
