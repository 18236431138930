import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Image = ({ fileName, alt, style, className }: any) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query {
			allImageSharp {
				nodes {
					fluid(maxWidth: 300) {
						originalName
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)

	const fluid = allImageSharp.nodes.find((image: any) => image.fluid.originalName === fileName).fluid

	return (
		<figure className={className}>
			<Img fluid={fluid} alt={alt} style={style} />
		</figure>
	)
}

export default Image
