import React from "react"
import { Stage, Opener, Features, Agenda, Demo, Join, Share } from "../components/landing"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import scrollTo from "gatsby-plugin-smoothscroll"

const IndexPage = () => {
	const handleClick = (name: any) => {
		scrollTo(`#${name}`)
	}
	return (
		<Layout onClick={handleClick}>
			<SEO title="Home" />
			<div id="home"></div>
			<Stage />
			<div id="explore"></div>
			<Opener />
			<Features />
			<Agenda />
			<Demo />
			<div id="join"></div>
			<Join />
			<div id="vision"></div>
			<Share />
			<div id="contact"></div>
		</Layout>
	)
}

export default IndexPage
