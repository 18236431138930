import React, { Fragment } from "react"
import styled from "styled-components"
import {
	IconActivity,
	IconBodywork,
	IconHealth,
	IconMeditation,
	IconGuidance,
	IconEnergy,
	IconPsychic,
	IconMystic,
	IconSensory,
} from "../icons"
import { StyledContainer, StyledBlock, StyledH2, StyledText } from "../styled"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { theme } from "../styled/theme"

const StyledCategory: any = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: center;
	align-items: center;
	color: #04aaaa;

	svg {
		height: 50px;
		width: 50px;
		margin-bottom: 12px;
	}
`

const StyledSliderSection: any = styled.div`
	@media (max-width: 1023px) {
		display: grid !important;
		grid-template-columns: repeat(3, 1fr);
		align-items: center;
		justify-content: center;
	}
`

const StyledAgendaSlider: any = styled.div`
	@media (max-width: 1023px) {
		.slick-slider {
			padding: 24px 0;
		}
		.slick-dots li {
			height: 10px;
			width: 10px;

			button {
				background: #fff;
				border: 1px solid ${theme.colors.blue};
				border-radius: 10px;
				height: 10px;
				width: 10px;
				transition: all 0.3s ease-in-out;

				&:before {
					display: none !important;
				}
			}

			&.slick-active {
				button {
					background: ${theme.colors.blue};
					border: 1px solid #00000000;
				}
			}
		}
	}
	@media (min-width: 1024px) {
		display: none;
	}
`

const StyledAgendaGrid: any = styled.div`
	@media (max-width: 1023px) {
		display: none;
	}
	@media (min-width: 1024px) {
		display: flex;
		width: 100%;
		justify-content: space-between;
		margin-top: 40px;
		margin-bottom: 50px;

		${StyledSliderSection} {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}
`

const StyledAgendaBlock: any = styled.div`
	@media (min-width: 1024px) {
		p {
			max-width: 488px;
		}
	}
`

const AgendaIcons = () => (
	<Fragment>
		<StyledSliderSection>
			<StyledCategory>
				<IconActivity color="#04AAAA" />
				<StyledText small uppercase>
					Activity
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconBodywork color="#04AAAA" />
				<StyledText small uppercase>
					Bodywork
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconHealth color="#04AAAA" />
				<StyledText small uppercase>
					Remedy
				</StyledText>
			</StyledCategory>
		</StyledSliderSection>
		<StyledSliderSection>
			<StyledCategory>
				<IconMeditation color="#04AAAA" />
				<StyledText small uppercase>
					Meditation
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconGuidance color="#04AAAA" />
				<StyledText small uppercase>
					Guidance
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconSensory color="#04AAAA" />
				<StyledText small uppercase>
					Perception
				</StyledText>
			</StyledCategory>
		</StyledSliderSection>
		<StyledSliderSection>
			<StyledCategory>
				<IconEnergy color="#04AAAA" />
				<StyledText small uppercase>
					Energy
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconPsychic color="#04AAAA" />
				<StyledText small uppercase>
					Psychic
				</StyledText>
			</StyledCategory>
			<StyledCategory>
				<IconMystic color="#04AAAA" />
				<StyledText small uppercase>
					Mystic
				</StyledText>
			</StyledCategory>
		</StyledSliderSection>
	</Fragment>
)

const AgendaSlider = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
	}

	return (
		<Slider {...settings}>
			<StyledSliderSection>
				<StyledCategory>
					<IconActivity color="#04AAAA" />
					<StyledText small uppercase>
						Activity
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconBodywork color="#04AAAA" />
					<StyledText small uppercase>
						Bodywork
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconHealth color="#04AAAA" />
					<StyledText small uppercase>
						Remedy
					</StyledText>
				</StyledCategory>
			</StyledSliderSection>
			<StyledSliderSection>
				<StyledCategory>
					<IconMeditation color="#04AAAA" />
					<StyledText small uppercase>
						Meditation
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconGuidance color="#04AAAA" />
					<StyledText small uppercase>
						Guidance
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconSensory color="#04AAAA" />
					<StyledText small uppercase>
						Perception
					</StyledText>
				</StyledCategory>
			</StyledSliderSection>
			<StyledSliderSection>
				<StyledCategory>
					<IconEnergy color="#04AAAA" />
					<StyledText small uppercase>
						Energy
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconPsychic color="#04AAAA" />
					<StyledText small uppercase>
						Psychic
					</StyledText>
				</StyledCategory>
				<StyledCategory>
					<IconMystic color="#04AAAA" />
					<StyledText small uppercase>
						Mystic
					</StyledText>
				</StyledCategory>
			</StyledSliderSection>
		</Slider>
	)
}

const Agenda = () => {
	return (
		<StyledAgendaBlock>
			<StyledBlock>
				<StyledContainer>
					<StyledH2>The Holistic Agenda</StyledH2>
					<StyledAgendaGrid>
						<AgendaIcons />
					</StyledAgendaGrid>
					<StyledText landing>
						Raise curates a variety of holistic services that will enable users to solve personal issues and find new
						perspectives to live a healthy and balanced lifestyle.
					</StyledText>
				</StyledContainer>
				<StyledAgendaSlider>
					<AgendaSlider />
				</StyledAgendaSlider>
			</StyledBlock>
		</StyledAgendaBlock>
	)
}

export default Agenda
