import React from "react"
import styled from "styled-components"
import * as Video from "../../images/stage-background.mp4"

const StyledVideo = styled.video`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	z-index: -1;
`

export default function StageVideo() {
	return (
		<StyledVideo autoPlay muted loop playsInline id="stage_video">
			<source src={Video} type="video/mp4" />
		</StyledVideo>
	)
}
